import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRequestData, usePrefs, useLabels } from 'wsm-common-data';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';
import generateUID from '../../util/modern/generateUID';

const InputDisplay = ({
	inputRef = React.createRef(),
	inputElementRef = React.createRef(),
	openModal,
	isOpen,
	onClear,
	type,
	handleSubmission,
	searchTerm,
	handleOnChangeInput
}) => {
	const labels = useLabels();
	const { searchPlaceholderLabel } = usePrefs();
	const { windowId, widgetName } = useRequestData();

	const { pageAlias } = useSelector((state) => state.widgetData);

	const inputId = useMemo(
		() => generateUID(`${pageAlias}_${windowId}`),
		[pageAlias, windowId]
	);

	const inputClassNames = [
		'form-control',
		'site-text-search-input',
		'font-size-ios-zoom-override',
		'form-control-transparent',
		'pl-0'
	];
	const clearClassNames = [
		'btn-unstyled text-link-muted',
		'py-3',
		'px-4',
		'border-neutral-0-alpha-3',
		!isOpen && 'hidden'
	];

	return (
		<>
			<div
				ref={inputRef}
				aria-label={labels.get(searchPlaceholderLabel)}
				data-type={type}
				data-testid="modern-input-display"
			>
				<form
					className="form-control spacing-reset d-flex h-auto"
					autoComplete="off"
					data-form-tracking-id="SITEWIDE_SEARCH"
					data-form-tracking-type="typedSearch"
					role="search"
					onSubmit={(e) => handleSubmission(e)}
				>
					{/* eslint-disable jsx-a11y/click-events-have-key-events */}
					<span
						className="ml-3 mr-3 my-3"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSubmission();
								inputElementRef.current?.focus();
							}
						}}
						onClick={() => {
							handleSubmission();
							inputElementRef.current?.focus();
						}}
						role="button"
						tabIndex="0"
						aria-label={labels.get('SEARCH')}
						type="button"
					>
						{/* eslint-disable jsx-a11y/click-events-have-key-events */}
						<i
							className="ddc-icon ddc-icon-search icon-size-2"
							aria-hidden="true"
						/>
					</span>
					<label
						id="site-search-label"
						htmlFor={inputId}
						className="sr-only"
					>
						{labels.get(searchPlaceholderLabel)}
					</label>
					<input
						id={inputId}
						className={setClassNames(inputClassNames)}
						aria-label={labels.get('SEARCH_BY_MAKE_MODEL_ETC_ARIA')}
						placeholder={labels.get(searchPlaceholderLabel)}
						onClick={openModal}
						value={searchTerm}
						onFocus={openModal}
						onChange={(e) => handleOnChangeInput(e.target.value)}
						maxLength={100}
						data-testid="modern-input"
						ref={inputElementRef}
					/>
					<button
						type="button"
						tabIndex="0"
						aria-label={labels.get('CLEAR')}
						className={setClassNames(clearClassNames)}
						onClick={() => {
							trackEvent(widgetName, windowId, {
								action: 'clicked',
								element: 'clear button',
								result: 'search field cleared'
							});
							onClear();
							inputElementRef.current?.focus();
							// Place cursor at the end of empty input
							inputElementRef.current?.setSelectionRange(
								inputElementRef.current.value.length,
								inputElementRef.current.value.length
							);
						}}
						data-testid="clear-button"
					>
						{labels.get('CLEAR')}
					</button>
				</form>
			</div>
		</>
	);
};

InputDisplay.propTypes = {
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputElementRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	openModal: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClear: PropTypes.func.isRequired,
	type: PropTypes.string,
	handleSubmission: PropTypes.func,
	searchTerm: PropTypes.string,
	handleOnChangeInput: PropTypes.func
};

export default InputDisplay;
